import cover1 from "@/assets/images/1_การใช้งานทั่วไป.png";
import cover2 from "@/assets/images/2_การเตรียมข้อมูล.png";
import cover3 from "@/assets/images/3_การตรวจนับ.png";
import cover4 from "@/assets/images/4_การแจ้งซ้อม.png";
import cover5 from "@/assets/images/5_การยืมคืน.png";
// 0 guest
// 123 admin
// 4 user
// 56 technician
export default {
  isLoading: false,
  mockToken: null,
  id: null,
  key: "Sponga_Verscan_Link",
  courseData: [
    {
      course: {
        title: "การใช้งานทั่วไป (Version 1)",
        sub_title:
          "แนะนำวิธีการใช้งานทั่วไป ตั้งเเต่การลงทะเบียนเข้าใช้งานระบบ การลงทะเบียนสินทรัพย์ เเละการสเเกนเพื่อตรวจนับสินทรัพย์",
        course_id: 1,
        level: "Basic",
        cover_img: cover1,
        couresTime: "5:08",
        link: [
          {
            id: 1,
            video_id: 1,
            name: "การลงทะเบียนผู้ใช้ใหม่",
            link: "1b17df50523a08f0f9b31779b9f045d8",
            duration: "1:29",
            thumbnail: "https://api.verscan.com/sponga/Content1_1.png",
          },
          {
            id: 2,
            video_id: 2,
            name: "การลงทะเบียนสินทรัพย์",
            link: "0a783705ab74c226f06061ba795e4bda",
            duration: "1:39",
            thumbnail: "https://api.verscan.com/sponga/Content1_2.png",
          },
          {
            id: 3,
            video_id: 3,
            name: "การดูข้อมูลสินทรัพย์ทั้งหมด",
            link: "9925c4e89b9645d90144de1507a14b6c",
            duration: "0:56",
            thumbnail: "https://api.verscan.com/sponga/Content1_3.png",
          },
          {
            id: 4,
            video_id: 4,
            name: "การสเเกนตรวจนับ",
            link: "c76d7cefbbf39e8c5bf5fb7356ea6882",
            duration: "1:04",
            thumbnail: "https://api.verscan.com/sponga/Content1_4.png",
          },
        ],
        permission: [0, 1, 2, 3, 4, 5, 6],
      },
    },
    {
      course: {
        title: "การเตรียมข้อมูล",
        sub_title:
          "แนะนำวิธีการตั้งค่าระบบ VERSCAN ตั้งเเต่การเพิ่มผู้ใช้งาน การนำเข้าข้อมูลสินทรัพย์ การเเก้ไขข้อมูลสินทรัพย์ และการตั้งค่าแผนผังอาคาร",
        course_id: 2,
        level: "Basic",
        cover_img: cover2,
        couresTime: "10:15",
        link: [
          {
            id: 1,
            video_id: 5,
            name: "การเพิ่มผู้ใช้งาน",
            link: "95a99c4f7e3ea2bb8c5a96beb40d38f0",
            duration: "1:12",
            thumbnail: "https://api.verscan.com/sponga/Content2_1.png",
          },
          {
            id: 2,
            video_id: 6,
            name: "การเเก้ไข/ลบผู้ใช้งาน",
            link: "617146a81ff9884b2141c3a85897b196",
            duration: "1:10",
            thumbnail: "https://api.verscan.com/sponga/Content2_2.png",
          },
          {
            id: 3,
            video_id: 7,
            name: "การนำเข้าข้อมูลผ่าน Excel",
            link: "f813872aaf6d7f8c6995adb7963f387d",
            duration: "1:35",
            thumbnail: "https://api.verscan.com/sponga/Content2_3.png",
          },
          {
            id: 4,
            video_id: 8,
            name: "การเเก้ไขข้อมูลผ่าน Excel",
            link: "02a61873828e204135667a80512dfa64",
            duration: "1:13",
            thumbnail: "https://api.verscan.com/sponga/Content2_4.png",
          },
          {
            id: 5,
            video_id: 9,
            name: "การเเก้ไข/ลบ ข้อมูลสินทรัพย์",
            link: "bd875351d8168ddb7b41e09224123e2d",
            duration: "1:15",
            thumbnail: "https://api.verscan.com/sponga/Content2_5.png",
          },
          {
            id: 6,
            video_id: 10,
            name: "การนำเข้าข้อมูลผ่าน VERSCAN",
            link: "298d6ee5a93db776acd62f3b56b95f26",
            duration: "1:12",
            thumbnail: "https://api.verscan.com/sponga/Content2_6.png",
          },
          {
            id: 7,
            video_id: 11,
            name: "การตั้งค่าแผนผังอาคาร",
            link: "48f873cac84778707bfc57438d5cb8bd",
            duration: "1:38",
            thumbnail: "https://api.verscan.com/sponga/Content2_7.png",
          },
        ],
        permission: [1, 2, 3],
      },
    },
    {
      course: {
        title: "การตรวจนับ",
        sub_title:
          "แนะนำวิธีการตรวจนับสินทรัพย์ ตั้งเเต่การสร้างรอบตรวจนับสินทรัพย์ การดูสรุปผลการตรวจนับ และการเซ็นรับรองเพื่อปิดรอบการตรวจนับ",
        course_id: 3,
        level: "Basic",
        cover_img: cover3,
        couresTime: "5:10",
        link: [
          {
            id: 1,
            video_id: 12,
            name: "การสร้างรอบตรวจนับ",
            link: "c55d34145f63ba4ed185d83d1cedc709",
            duration: "1:10",
            thumbnail: "https://api.verscan.com/sponga/Content3_1.png",
          },
          {
            id: 2,
            video_id: 13,
            name: "การดูสรุปผลการตรวจนับ",
            link: "ca801ac44ba9849ea8b34ae6cb1f7a4e",
            duration: "1:21",
            thumbnail: "https://api.verscan.com/sponga/Content3_2.png",
          },
          {
            id: 3,
            video_id: 14,
            name: "การเซ็นรับรอง",
            link: "44e55c12e451d7cfea4a0ba0d56b3e32",
            duration: "1:41",
            thumbnail: "https://api.verscan.com/sponga/Content3_3.png",
          },
          {
            id: 4,
            video_id: 15,
            name: "การปิดรอบตรวจนับ",
            link: "62177bf76d47b75e6fc99bbed714c85a",
            duration: "0:58",
            thumbnail: "https://api.verscan.com/sponga/Content3_4.png",
          },
        ],
        permission: [1, 2, 3],
      },
    },
    {
      course: {
        title: "การใช้งานระบบแจ้งซ่อม",
        sub_title:
          "ซอฟต์แวร์สำหรับตรวจนับสินทรัพย์ที่จะช่วยเพิ่มความสะดวกในการตรวจนับสินทรัพย์ อีกทั้งยังมีความปลอดภัยในการเก็บข้อมูลสูงและสามารถดูข้อมูลสรุปรายงานการตรวจนับสินทรัพย์ได้แบบเรียลไทม์สามารถใช้งานได้ทั้งบน Website และ Mobile Application (iOS, Android)",
        course_id: 4,
        level: "Basic",
        cover_img: cover4,
        couresTime: "6:46",
        link: [
          {
            id: 1,
            video_id: 16,
            name: "การแจ้งซ่อมทั่วไป",
            link: "ee6116392358f8229693bda52061cf3e",
            duration: "1:03",
            permission: [1, 2, 3, 4, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_1.png"
          },
          {
            id: 2,
            video_id: 17,
            name: "การแจ้งซ่อมผ่านรอบตรวจนับ",
            link: "bd894dad4dde073314155c5fec2c45d9",
            duration: "0:49",
            permission: [1, 2, 3, 4, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_2.png"
          },
          {
            id: 3,
            video_id: 18,
            name: "การแจ้งซ่อมผ่านเมนูแจ้งซ่อม",
            link: "3f76a088db1cd5e0bfcddd86dc70948a",
            duration: "1:02",
            permission: [1, 2, 3, 4, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_3.png"
          },
          {
            id: 4,
            video_id: 19,
            name: "ติดตามการแจ้งซ่อมของฉัน",
            link: "cb93a595e3b539712cce370f85410f87",
            duration: "0:45",
            permission: [1, 2, 3, 4, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_4.png"
          },
          {
            id: 5,
            video_id: 20,
            name: "การจัดการและมอบหมายงานแจ้งซ่อม (หัวหน้าช่าง)",
            link: "187b7140c3fbb55db8189786ea76e8a2",
            duration: "1:41",
            permission: [1, 2, 3, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_5.png"
          },
          {
            id: 6,
            video_id: 21,
            name: "การจัดการงานแจ้งซ่อม (ช่าง)",
            link: "9e0c8d87843410e3f4a01151b4aa9847",
            duration: "1:26",
            permission: [1, 2, 3, 5, 6],
            thumbnail: "https://api.verscan.com/sponga/Content4_6.png"
          },
        ],
        permission: [1, 2, 3, 4, 5, 6],
      },
    },
        {
      course: {
        title: "การใช้งานระบบยืมคืน",
        sub_title:
          "ซอฟต์แวร์สำหรับตรวจนับสินทรัพย์ที่จะช่วยเพิ่มความสะดวกในการตรวจนับสินทรัพย์ อีกทั้งยังมีความปลอดภัยในการเก็บข้อมูลสูงและสามารถดูข้อมูลสรุปรายงานการตรวจนับสินทรัพย์ได้แบบเรียลไทม์สามารถใช้งานได้ทั้งบน Website และ Mobile Application (iOS, Android)",
        course_id: 5,
        level: "Basic",
        cover_img: cover5,
        couresTime: "8:13",
        link: [
          {
            id: 1,
            video_id: 22,
            name: "การเพิ่มและนำออกสินทรัพย์ในระบบยืม-คืน",
            link: "52a1950420c6979c92fdb9f18e1ebbda",
            duration: "1:12",
            thumbnail: "https://api.verscan.com/sponga/Content5_1.png"
          },
          {
            id: 2,
            video_id: 23,
            name: "การสร้างคำขอยืมสินทรัพย์",
            link: "3bbc02c8af98f90e4c6e257e2fa55fdf",
            duration: "0:59",
            thumbnail: "https://api.verscan.com/sponga/Content5_2.png"
          },
          {
            id: 3,
            video_id: 24,
            name: "การจัดการและติดตามสถานะยืม-คืน",
            link: "6631e80c7be79b285e9251b64dc9e920",
            duration: "1:44",
            thumbnail: "https://api.verscan.com/sponga/Content5_3.png"
          },
          {
            id: 4,
            video_id: 25,
            name: "การจัดการและติดตามสถานะยืม-คืนด้วยการ Scan QR code",
            link: "a9072ca8a6ce3f29703522a93a63db25",
            duration: "1:18",
            thumbnail: "https://api.verscan.com/sponga/Content5_4.png"
          },
          {
            id: 5,
            video_id: 26,
            name: "การสร้างคำขอยืมสินทรัพย์ (ผู้ใช้งานทั่วไป)",
            link: "269c475eae8b09ec063b409c34e1a54a",
            duration: "0:58",
            thumbnail: "https://api.verscan.com/sponga/Content5_5.png"
          },
          {
            id: 6,
            video_id: 27,
            name: "การติดตามสถานะและดูประวัติการยืม-คืน",
            link: "dd50512e1203faac44a498727d350ed6",
            duration: "1:45",
            thumbnail: "https://api.verscan.com/sponga/Content5_6.png"
          },
        ],
        permission: [1,2,3,4,5,6],
      },
    },
  ],
  courseDataV2: [
    {
      course: {
        title: "การใช้งานทั่วไป (Version 2)",
        sub_title:
          "แนะนำวิธีการใช้งานทั่วไป ตั้งเเต่การลงทะเบียนเข้าใช้งานระบบ การลงทะเบียนสินทรัพย์ เเละการสเเกนเพื่อตรวจนับสินทรัพย์",
        course_id: 1,
        level: "Basic",
        cover_img: cover1,
        couresTime: "5:08",
        link: [
          {
            id: 1,
            video_id: 1,
            name: "การลงทะเบียนผู้ใช้ใหม่",
            link: "4ed4ee672ca7af40267a9b4b4d35b506",
            duration: "1:29",
            thumbnail: "https://static.verscan.com/Sponga/C1_1.png",
          },
          {
            id: 2,
            video_id: 2,
            name: "การลงทะเบียนสินทรัพย์",
            link: "1c14fac7f2a4cddad72215c0e61356b2",
            duration: "1:39",
            thumbnail: "https://static.verscan.com/Sponga/C1_2.png",
          },
          {
            id: 3,
            video_id: 3,
            name: "การดูข้อมูลสินทรัพย์ทั้งหมด",
            link: "e1b918f9686c2ee6b24ad2324abec346",
            duration: "0:56",
            thumbnail: "https://static.verscan.com/Sponga/C1_3.png",
          },
          {
            id: 4,
            video_id: 4,
            name: "การสเเกนตรวจนับ",
            link: "b55da28c1570d0c4d3ee0a3d708312a7",
            duration: "1:04",
            thumbnail: "https://static.verscan.com/Sponga/C1_4.png",
          },
        ],
        permission: [0, 1, 2, 3, 4, 5, 6],
      },
    },
  ],

};
