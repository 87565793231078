import axios from "axios";
import store from "../store";
import router from "../router";

const instance = axios.create();

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let originalRequest = error.config;
    if (error.response) {
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !error.config.url.includes("/tokenLogin") &&
        !error.config.url.includes("/genLoginToken")
      ) {
        originalRequest._retry = true;
        try {
          await store.dispatch("autoLogin");
          originalRequest.headers["Authorization"] =
            "Bearer " + store.getters.getToken;
          return axios.request(originalRequest);
        } catch (err) {
          router.replace({ name: "Home" });
          return Promise.reject(err);
        }
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
