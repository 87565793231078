<template>
  <div class="d-flex">
    <label class="checkbox-container"
      ><p v-if="label" class="m-0" style="padding-top: 2px">{{ label }}</p>
      <input
        type="checkbox"
        :checked="checked"
        @change="toggleCheck($event.target.checked)"
      />
      <span class="checkmark"></span>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emit: ["toggleCheck"],
  props: ["checked", "label"],
  methods: {
    toggleCheck(value) {
      console.log(this.label);
      this.$emit('toggleCheck', value, this.label);
    }
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 10px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #798da5;
  transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #8888FF;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
