<template>
  <div class="course-card-container">
    <router-link
      v-if="type === 'curriculum'"
      :to="{
        name: 'CurriculumDetail',
        params: {
          curriculumId: course.id,
        },
  
      }"
    >
      <div class="course-card d-flex flex-column">
        <div class="thumbnail-container">
          <img :src="course.cover_img" alt="" />
          <!-- <img src="../assets/images/course.jpg" alt="" /> -->
        </div>
        <div class="d-flex flex-column justify-content-between h-100 content">
          <p class="courseCurriculum m-0 text-big font-weight-bold">
            {{ course.curriculum }}
          </p>
          <p class="description color-text">
            {{ course.desc }}
          </p>
          <div class="level">
            <div class="d-flex align-items-center" v-if="course.level.Basic">
              <img src="@/assets/images/level/basic.svg" alt="" />
              <p class="mb-0 color-text">Basic ({{ course.level.Basic }})</p>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="course.level.Intermediate"
            >
              <img src="@/assets/images/level/intermediate.svg" alt="" />
              <p class="mb-0 color-text">
                Intermediate ({{ course.level.Intermediate }})
              </p>
            </div>
            <div class="d-flex align-items-center" v-if="course.level.Advance">
              <img src="@/assets/images/level/advance.svg" alt="" />
              <p class="mb-0 color-text">
                Advance ({{ course.level.Advance }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <router-link
      v-if="type === 'course'"
      :to="{
        name: 'Detail',
        params: {
          courseId: course.course.course_id,
        },
      }"
    >
      <div class="course-card d-flex flex-column">
        <div class="thumbnail-container">
          <img :src="course.course.cover_img" alt="" />
          <!-- <img src="../assets/images/course.jpg" alt="" /> -->
        </div>
        <div class="d-flex flex-column justify-content-between h-100 content">
          <p class="courseCurriculum m-0 text-big font-weight-bold">
            {{ course.course.title }}
          </p>
          <p class="description color-text">
            {{ course.course.sub_title }}
          </p>
          <div>
            <p class="m-0">
              {{ `${course.lecturer.first_name} ${course.lecturer.last_name}` }}
            </p>
            <div class="d-flex align-items-center flex-wrap star">
              <b-form-rating
                :value="
                  course.reviewData.averageRating
                    ? course.reviewData.averageRating.toFixed(1)
                    : 0
                "
                inline
                readonly
                class="m-0 pl-0"
                style="color: #0430ad"
                no-border
                size="lg"
              ></b-form-rating>
              <p class="color-primary text-big font-weight-bold m-0">
                {{
                  course.reviewData.averageRating
                    ? course.reviewData.averageRating.toFixed(1)
                    : "0.0"
                }}
                <!-- 5.0 -->
              </p>
            </div>
            <div class="d-flex align-items-center mb-2">
              <img
                class="level-img"
                v-if="course.course.level === 'Basic'"
                src="@/assets/images/level/basic.svg"
                alt=""
              />
              <img
                class="level-img"
                v-if="course.course.level === 'Intermediate'"
                src="@/assets/images/level/intermediate.svg"
                alt=""
              />
              <img
                class="level-img"
                v-if="course.course.level === 'Advance'"
                src="@/assets/images/level/advance.svg"
                alt=""
              />
              <p class="mb-0 color-text">{{ course.course.level }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold m-0">
                ใช้เวลา {{ secToMin(course.course.total_second) }}
              </p>
              <p class="font-weight-bold m-0" v-if="course.price.price > 0">
                ฿ {{ numberComma(course.price.price.toFixed(2)) }}
              </p>
              <p class="font-weight-bold m-0" v-else>Free</p>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <div v-if="type === 'myCourse'" class="d-flex flex-column h-100">
      <router-link class="h-100" :to="routeTo">
        <div class="course-card d-flex flex-column">
          <div class="thumbnail-container">
            <img :src="course.courseImg" alt="" />
          </div>
          <div class="d-flex flex-column justify-content-between h-100 content">
            <p class="text-big font-weight-bold">
              {{ course.courseName }}
            </p>
            <p class="text-big font-weight-bold color-primary m-0">
              เรียนไปแล้ว {{ course.progress || 0 }}%
            </p>
          </div>
        </div>
      </router-link>
      <p v-if="course.isExpired" class="text-right my-1">
        <span style="color: red">หมดเวลาเรียนแล้ว</span
        ><span
          v-if="!course.is_free_extended"
          @click="extendTime(course)"
          style="cursor: pointer"
          >&nbsp;|&nbsp;ต่อเวลาเรียน</span
        >
      </p>
      <p v-else class="text-right my-1">
        <span v-if="course.timeLeft >= 0">
          หมดเวลาเรียนอีก {{ course.timeLeft }} วัน
        </span>
        <span v-else style="visibility: hidden">hiding</span>
      </p>
    </div>
    <router-link
      v-if="type === 'Temporary'"
      :to="`/lesson/${course.course.course_id}`"
    >
      <div class="course-card d-flex flex-column">
        <div class="thumbnail-container">
          <img :src="course.course.cover_img" alt="" />
          <!-- <img src="../assets/images/course.jpg" alt="" /> -->
        </div>
        <div class="d-flex flex-row-reverse">
          <div class="start-button d-flex justify-content-end px-1">
            <img
              src="../assets/images/playWhite.svg"
              alt="playIcon"
              width="15px"
              height="15px"
              style="margin: auto 0"
            />
            <p class="pl-1" style="color: #ffffff; margin: auto 4px">
              เริ่มเรียน
            </p>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-between h-100 content">
          <p class="courseCurriculum m-0 text-big font-weight-bold">
            {{ course.course.title }}
          </p>
          <p class="description color-text">
            {{ course.course.sub_title }}
          </p>
          <div>
            <div
              class="d-flex align-items-center mb-2"
              v-if="course.course.level === 'Basic'"
            >
              <p class="mb-0 mr-2" style="color: #757575">ระดับ:</p>
              <div class="level-chip easy">
                <p class="m-0">ง่าย</p>
              </div>
            </div>
            <div
              class="d-flex align-items-center mb-2"
              v-if="course.course.level === 'Intermediate'"
            >
              <p class="mb-0 mr-2" style="color: #757575">ระดับ:</p>
              <div class="level-chip intermediate">
                <p class="m-0">ปานกลาง</p>
              </div>
            </div>
          </div>
          <div>
            <p class="mb-0 mr-2" style="color: #757575">
              เวลาเรียน: &nbsp;&nbsp;<u>{{ course.course.couresTime }}</u> นาที
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import numberComma from "../util/numberComma";
import { secToMin } from "../util/convertTime";
export default {
  props: ["course", "type", "time"],
  emits: ["extend"],
  computed: {
    // timeInMinute() {
    //   let minutes = Math.floor(this.course.course.total_second / 60);
    //   return minutes;
    // },
    routeTo() {
      if (this.course.isExpired) {
        return {
          name: "Detail",
          params: {
            courseId: this.course.course_id,
          },
        };
      } else {
        return {
          name: "Lesson",
          params: {
            courseId: this.course.course_id,
          },
        };
      }
    },
  },
  methods: {
    numberComma,
    secToMin,
    extendTime(course) {
      this.$emit("extend", {
        user_id: this.$store.getters.getUser.user_id,
        course_price_id: course.course_price_id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.course-card-container {
  height: 100%;
  max-width: 370px;
  margin: 0 auto;
}

.course-card {
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.course-card .content {
  padding: 10px;
}

.courseCurriculum {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
}

.level {
  min-height: 70px;
}

.level img,
.level-img {
  margin-right: 10px;
}

.start-button {
  height: 28px;
  width: 90px;
  background-color: #ffbb0b;
  border-radius: 20px 0 0 20px;
  margin-top: -17px;
  z-index: 1;
}

.star >>> .b-rating-star:first-child {
  padding-left: 0;
}

.level-chip {
  padding: 3px 10px;
  border-radius: 10px;

  &.easy {
    color: #31c23e;
    background-color: #b1ecb6;
  }

  &.intermediate {
    color: #31c23e;
    background-color: #fcef79;
  }
}
</style>
