<template>
  <base-dialog small fixed :show="questionDisplay">
    <div class="questionnaire font-weight-bold" ref="questionnaire">
      <div v-if="questionPage <= questionList.length">
        <img
          class="d-block mx-auto"
          src="../assets/images/questionnaire.png"
          alt=""
        />
        <h4 class="text-big text-line color-primary">
          <span>แบบสอบถาม</span>
        </h4>
        <p class="text-center color-primary text-big font-weight-bold">
          {{ questionList[questionPage - 1].question }}
        </p>
        <div
          class="questionBox"
          v-if="questionList[questionPage - 1].type === 'select'"
        >
          <button
            v-for="(choice, idx) in questionList[questionPage - 1].choices"
            :key="idx"
            class="choice"
            :class="{
              selected: questionAnswer[questionPage - 1].choices[0] === idx + 1,
            }"
            @click="selectChoice(idx + 1)"
          >
            <p class="font-weight-bold text-big">
              {{ choice.choice }}
            </p>
          </button>
        </div>
        <div
          class="questionBox"
          v-else-if="questionList[questionPage - 1].type === 'checkbox'"
          style="margin: 10px"
        >
          <div
            v-for="(choice, idx) in questionList[questionPage - 1].choices"
            :key="idx"
            class="d-flex"
            style="margin-bottom: 10px"
          >
            <CustomCheckbox
              v-on:toggleCheck="selectCheckbox"
              :label="`${idx + 1}. ${choice.choice}`"
              :checked="questionAnswer[questionPage - 1].choices[idx]"
            />
          </div>
        </div>
        <div
          class="questionBox"
          v-else-if="questionList[questionPage - 1].type === 'radio'"
          style="margin: 10px"
        >
          <div
            v-for="(choice, idx) in questionList[questionPage - 1].choices"
            :key="idx"
            style="margin-bottom: 10px"
          >
            <div class="text-big">
              {{ `${idx + 1}. ${choice.choice}` }}
            </div>
            <CustomRadio
              v-on:picking="selectRadio(idx, $event)"
              :picked="questionAnswer[questionPage - 1].choices[idx]"
            />
          </div>
        </div>
        <button
          v-if="questionPage < questionList.length"
          class="secondary next"
          :disabled="!to_go"
          @click="nextQuestionPage"
        >
          <p class="font-weight-bold text-big">
            ต่อไป
          </p>
        </button>
        <button
          v-else
          class="secondary next"
          :disabled="!to_go"
          @click="submitSurvey"
        >
          <p class="font-weight-bold text-big">
            ส่งแบบสอบถาม
          </p>
        </button>
        <p
          v-if="questionPage > 1"
          class="text-big color-secondary text-center"
          style="cursor: pointer; margin-top: 15px;"
          @click="prevQuestionPage"
        >
          <u>ย้อนกลับ</u>
        </p>
      </div>
      <div v-else>
        <img
          class="d-block mx-auto"
          src="../assets/images/success.png"
          alt=""
          style="margin-top: 71px;"
        />
        <p
          class="color-primary text-big font-weight-bold text-center"
          style="margin-bottom: 131px; margin-top: 61px;"
        >
          ขอบคุณสำหรับการตอบแบบสอบถาม
        </p>
        <button class="secondary next" @click="closeQuestionDialog">
          <p class="font-weight-bold text-big">
            ปิด
          </p>
        </button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import axios from "../api/faAPI";
import baseUrl from "../util/backend";
import CustomCheckbox from "../components/CustomCheckbox.vue";
import CustomRadio from "../components/CustomRadio.vue";
import { nextTick } from "process";
const survey_group_id = 1;
export default {
  emits: ["close"],
  components: { CustomCheckbox, CustomRadio },
  props: ["questionDisplay"],
  data() {
    return {
      questionPage: 1,
      questionList: [],
      questionAnswer: [],
      to_go: false,
    };
  },
  methods: {
    nextQuestionPage() {
      let togo = this.checkAnswer();
      if (togo) {
        this.questionPage += 1;
        const div = this.$refs.questionnaire;
        div.scrollTop = 0;
        nextTick(() => (this.to_go = this.checkAnswer()));
      } else {
        alert("Please Answer the Question!");
      }
      return togo;
    },
    prevQuestionPage() {
      this.questionPage -= 1;
      const div = this.$refs.questionnaire;
      div.scrollTop = 0;
      nextTick(() => (this.to_go = this.checkAnswer()));
    },
    closeQuestionDialog() {
      this.questionPage = 1;
      this.$emit("close");
      this.$router.replace({ name: "Home" });
    },
    async getSurveyQuestion(surveyQID) {
      try {
        const res = await axios.get(
          `${baseUrl}/getSurveyGroup?survey_group_id=${surveyQID}`
        );
        if (res.status == 200) {
          this.questionList = res.data.data.questions;
          for (let i in this.questionList) {
            this.questionList[i].type = this.questionList[i].choices[0].type;
          }
          this.generateAnswerList();
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async submitSurvey() {
      let surveyResult = {
        survey_group_id: survey_group_id,
        user_id: this.$store.getters.getUser.user_id,
        answer: this.generateAnswerResponse(this.questionAnswer),
      };
      if (this.nextQuestionPage()) {
        try {
          await axios.post(`${baseUrl}/createSurveyResult`, surveyResult);
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    selectRadio(idx, choice) {
      this.questionAnswer[this.questionPage - 1].choices[idx] = choice;
      nextTick(() => (this.to_go = this.checkAnswer()));
    },
    selectCheckbox(value, name) {
      let sel = name.split(".")[0];
      let selected = parseInt(sel);
      this.questionAnswer[this.questionPage - 1].choices[selected - 1] = value;
      nextTick(() => (this.to_go = this.checkAnswer()));
    },
    selectChoice(choice) {
      this.questionAnswer[this.questionPage - 1].choices = [choice];
      nextTick(() => (this.to_go = this.checkAnswer()));
    },
    generateAnswerList() {
      for (let i in this.questionList) {
        this.questionAnswer.push({
          survey_question_id: this.questionList[i].survey_question_id,
          type: this.questionList[i].type,
          choices:
            this.questionList[i].type === "select"
              ? [0]
              : Array(this.questionList[i].choices.length).fill(0),
        });
      }
    },
    checkAnswer() {
      if (this.questionPage > this.questionList.length) {
        return true;
      }
      if (this.questionAnswer[this.questionPage - 1].type === "checkbox") {
        let isSelected = false;
        for (let i in this.questionAnswer[this.questionPage - 1].choices) {
          this.questionAnswer[this.questionPage - 1].choices[i] =
            this.questionAnswer[this.questionPage - 1].choices[i] === true;
          if (this.questionAnswer[this.questionPage - 1].choices[i] === true) {
            isSelected = true;
          }
        }
        return isSelected;
      } else {
        for (let i in this.questionAnswer[this.questionPage - 1].choices) {
          if (this.questionAnswer[this.questionPage - 1].choices[i] == 0)
            return false;
        }
      }
      return true;
    },
    generateAnswerResponse(questionAnswer) {
      let answer = [];
      for (let i in questionAnswer) {
        let q = questionAnswer[i];
        let a = { survey_question_id: q.survey_question_id, choices: [] };
        switch (q.type) {
          case "select":
            a.choices = [
              {
                survey_choice_id: this.questionList[i].choices[q.choices[0] - 1]
                  .survey_choice_id,
              },
            ];
            break;
          case "checkbox":
            for (let j in q.choices) {
              if (q.choices[j] === true) {
                a.choices.push({
                  survey_choice_id: this.questionList[i].choices[j]
                    .survey_choice_id,
                });
              }
            }
            break;
          case "radio":
            for (let j in q.choices) {
              a.choices.push({
                survey_choice_id: this.questionList[i].choices[j]
                  .survey_choice_id,
                result: q.choices[j],
              });
            }
            break;
        }
        answer.push(a);
      }
      return answer;
    },
  },
  mounted() {
    this.getSurveyQuestion(survey_group_id);
  },
};
</script>

<style scoped>
button {
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}

button.primary {
  background-color: #0430ad;
}

button.secondary {
  background-color: #07c5f8;
}

.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

.questionnaire {
  min-height: 543px;
  overflow-y: auto;
}

.questionnaire .choice {
  display: block;
  margin: 10px auto;
  background-color: #c4c4cc;
  width: 100%;
  padding: 20px 15px;
  max-width: 400px;
}

.questionnaire .choice.selected {
  background-color: #0430ad;
}

.questionnaire .next {
  display: block;
  margin: 0 auto;
  min-width: 170px;
}

.questionBox {
  min-height: 300px;
  max-height: 400px;
  overflow: auto;
  margin-bottom: 10px;
}
</style>
