const hostname = window.location.hostname;
switch (hostname) {
  case "dev-sponga.web.app":
  case "dev-sponga.firebaseapp.com":
    window.location.href = "https://dev.sponga.co/";
    break;
  case "uat-sponga.web.app":
  case "uat-sponga.firebaseapp.com":
    window.location.href = "https://uat.sponga.co/";
    break;
  case "project-sponga.web.app":
  case "project-sponga.firebaseapp.com":
    window.location.href = "https://sponga.co/";
    break;
  default:
    break;
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);

Vue.config.productionTip = false;

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import firebase from "firebase/compat/app";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE,
  messagingSenderId: process.env.MESSENGER,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
firebase.initializeApp(firebaseConfig);

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

import BaseDialog from "./components/BaseDialog.vue";
Vue.component("base-dialog", BaseDialog);

import VueGtag from "vue-gtag";

if (hostname === "uat.sponga.co") {
  Vue.use(VueGtag, {
    config: {
      id: "G-LS840E2GR0",
      params: {
        send_page_view: false,
        debug_mode: true,
      },
    },
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
