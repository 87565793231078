export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  MOCK_TOKEN(state, payload) {
    state.mockToken = payload;
  },
  DECRYPTED(state, payload) {
    state.id = payload;
  },
};
