import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import moduleAuth from "./auth";
import visitedVideoList from "./visitedVideoList";

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 1
    }),
  modules: ['visit'], //only save user module
  
})

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  plugins: [vuexCookie.plugin],
  modules: {
    auth: moduleAuth,
    visit: visitedVideoList,
  },
});
