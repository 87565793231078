export const stage = () => {
  const url = window.location.hostname;
  switch (url) {
    case "sponga.co":
      return "production";
    case "uat.sponga.co":
      return "uat";
    case "dev.sponga.co":
      return "dev";
    default:
      return "local";
  }
};

// const baseUrl = () => {
//   switch (stage()) {
//     case "production":
//       return "";
//     case "uat":
//       return "";
//     case "dev":
//       return "";
//     case "local":
//       return "http://localhost:3000";
//     default:
//       return "http://localhost:3000";
//   }
// };
