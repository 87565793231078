// export const secToMin = (second) => {
//   let minutes = Math.floor(second / 60);
//   return minutes > 0 ? minutes : 1;
// };
export const secToMin = (second) => {
  const sec = parseInt(second, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  let result = "";
  if (hours > 0) {
    result += `${hours} ชม. `;
  }
  if (minutes > 0) {
    result += `${minutes} นาที`;
  }
  if (hours === 0 && minutes === 0 && seconds > 0) {
    result += `1 นาที`;
  }
  return result;
};
