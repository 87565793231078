<template>
<div class="d-flex custom-radio">
  <label
      class="radio-container"
      v-for="idx in Array(5).keys()"
      :key="idx"
  ><p class="m-0" >{{ idx+1 }}</p>
    <input
        type="radio"
        :checked="pick[idx]"
        @change="picking(idx)"
    />
    <span class="checkmark"></span>
  </label>
</div>
</template>

<script>
export default {
  name: "CustomRadio",
  props: {
    picked: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      pick: Array(5).fill(false),
    }
  },
  methods: {
    picking (idx, emit=true) {
      this.pick = Array(5).fill(false);
      this.pick[idx] = true;
      if(emit) this.$emit('picking',idx+1);
    },
  },
  mounted() {
    //console.log(this.$props.picked);
    if(this.$props.picked){
      let p = this.$props.picked;
      this.picking(p-1,false);
    }
  }
}
</script>

<style scoped>
.custom-radio {
  flex-direction: row-reverse;
  max-width: 240px;
  justify-content: space-between;
  margin-top: 13px;
  margin-bottom: 14px;
}

/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e7e7e7;
  border-radius: 50%;
  border: 1px solid #0430AD
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #e7e7e7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  top: 0px;
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  /*to edit*/
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #0430AD;
}
</style>