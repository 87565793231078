import baseUrl from "../../util/backend";
import axios from "../../api/faAPI";

export default {
  state() {
    return {
      user: null,
      token: null,
      refreshToken: null,
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    },
  },
  actions: {
    async login(context, payload) {
      try {
        const res = await axios.post(`${baseUrl}/login`, payload);
        context.commit("setToken", res.data.token);
        context.commit("setRefreshToken", res.data.RefreshToken);
        context.commit("setUser", res.data.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.RefreshToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    async firebaseLogin(context, payload) {
      let url = "";
      try {
        if (payload.type === "google") {
          url = `${baseUrl}/googleLogin`;
        }
        if (payload.type === "facebook") {
          url = `${baseUrl}/facebookLogin`;
        }
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              Authorization: payload.token,
            },
          }
        );
        context.commit("setToken", res.data.token);
        context.commit("setRefreshToken", res.data.RefreshToken);
        context.commit("setUser", res.data.data);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.RefreshToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    async autoLogin({ commit, dispatch }) {
      const lastToken = localStorage.getItem("token");
      const lastRefreshToken = localStorage.getItem("refreshToken");
      try {
        const res = await axios.post(
          `${baseUrl}/tokenLogin`,
          {},
          {
            headers: {
              Authorization: `Bearer ${lastToken}`,
            },
          }
        );
        commit("setToken", lastToken);
        commit("setRefreshToken", lastRefreshToken);
        commit("setUser", res.data.data);
        axios.defaults.headers.common["Authorization"] = `Bearer ${lastToken}`;
      } catch (err) {
        if (err.response) {
          if (err.response.data.error === "jwt expired") {
            try {
              const res = await axios.post(
                `${baseUrl}/genLoginToken`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${lastRefreshToken}`,
                  },
                }
              );
              commit("setUser", res.data.data);
              commit("setToken", res.data.token);
              commit("setRefreshToken", lastRefreshToken);
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.data.token}`;
            } catch (err) {
              console.log(err);
              dispatch("logout");
              // const error = new Error(res.data.message || "Failed to authenticate.");
              const error = new Error("Please login again.");
              throw error.message;
            }
          } else {
            dispatch("logout");
            throw err.response.data.error || "error";
          }
        } else {
          throw err;
        }
      }
    },
    logout(context) {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      context.commit("setUser", null);
      context.commit("setToken", null);
      context.commit("setRefreshToken", null);
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    isAuth(state) {
      return state.token ? true : false;
    },
    getToken(state) {
      return state.token;
    },
  },
};
