<template>
  <div>
    <RegisterQuestion
      :questionDisplay="questionDisplay"
      @close="questionDisplay = false"
    />
    <base-dialog
      small
      :show="displaySubscribe"
      @close="displaySubscribe = false"
    >
      <form @submit.prevent="submitSubscribe">
        <div class="d-flex flex-column align-items-center interest">
          <p class="text-big font-weight-bold text-center">
            กรุณากรอกอีเมลหรือเบอร์โทรเพื่อรับข่าวสาร
          </p>
          <div class="d-flex w-100">
            <div
              class="switch"
              :class="{ selected: subscribeType === 'email' }"
              @click="subscribeType = 'email'"
            >
              อีเมล
            </div>
            <div
              class="switch"
              :class="{ selected: subscribeType === 'tel' }"
              @click="subscribeType = 'tel'"
            >
              เบอร์โทร
            </div>
          </div>
          <input
            v-if="subscribeType === 'email'"
            v-model="subscribeEmail"
            class="w-100"
            type="email"
            placeholder="อีเมล"
          />
          <input
            v-if="subscribeType === 'tel'"
            v-model="subscribeTel"
            class="w-100"
            type="number"
            placeholder="เบอร์โทร"
          />
          <button class="w-100">
            <p class="text-big">เตือนฉัน</p>
          </button>
        </div>
      </form>
    </base-dialog>
    <!-- SECTION - BANNER -->
    <div class="banner">
      <ByteArkPlayerContainer
        v-if="bannerType === 'live'"
        :options="playerOptions"
      />
      <!-- <div class="video-container">
        <iframe
          class="video-player"
          src="https://www.youtube.com/embed/5qap5aO4i9A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
      <carousel
        v-if="bannerType === 'image'"
        :perPage="1"
        loop
        autoplay
        :autoplayHoverPause="false"
        :autoplayTimeout="4000"
      >
        <slide v-for="banner in bannerList" :key="banner.banner_id">
          <div
            class="mx-auto slide-container"
            @click="goToBannerLink(banner.href)"
          >
            <div class="thumbnail-container">
              <img v-if="banner.url" :src="banner.url" alt="" />
              <div v-else></div>
            </div>
          </div>
        </slide>
      </carousel>
      <!-- <div class="promotion">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <h2 class="mr-3">เข้าร่วมหลักสูตร</h2>
                <div>
                  <img class="logo" src="../assets/images/Sponga_Logo.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <p class="text-big">
                แหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไป<br
                  class="d-none d-md-block"
                />
                และนักศึกษาแบบครบวงจร เพื่อหาความรู้ในการพัฒนาตนเอง
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="pl-3" style="border-left: 7px solid #07C5F8">
                <h4 class="color-secondary">
                  เริ่มสมัครคอร์สเรียนวันนี้ 787 THB / Monthly
                </h4>
                <h2 class="color-secondary">
                  ลดทันที 30% โปรโมชั่นนี้ ภายในวันนี้
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex my-3 subscribe text-big">
                <input type="email" placeholder="email address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="countdown" v-if="countdown">
      <div class="container">
        <div
          class="d-flex flex-column flex-lg-row align-items-center justify-content-center py-3"
        >
          <h4
            class="m-0 text-white flex-fill text-center text-md-right font-weight-normal"
            style="max-width:450px"
          >
            {{ countdown.name }}
          </h4>
          <div class="clock">
            <flip-countdown :deadline="formatCountdownDate"></flip-countdown>
          </div>
          <div class="flex-fill">
            <button class="interest-btn" @click="displaySubscribe = true">
              <p class="text-white text-big">สนใจเข้าร่วม</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- SECTION - Information -->
    <div class="container">
      <div class="info-card text-center text-md-right" style="margin-top: 80px">
        <h2 class="mb-4">
          <span class="color-primary">F</span
          ><span class="color-secondary">A</span>
          <span class="color-text"> Station คืออะไร?</span>
        </h2>
        <p
          class="color-text text-big"
          style="line-height: 40px;font-weight:300"
        >
          หลักสูตรการเรียนรู้
          ทั้งภาคทฤษฎีและภาคปฎิบัติสำหรับการเป็นที่ปรึกษาการเงิน (Financial
          Advisor: FA)
          และเป็นแหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไปแบบครบวงจร
          โดยหวังว่าจะเป็นทางเลือกให้กับประชาชนทั่วไป
          นักศึกษาที่กำลังจะจบการศึกษา รวมถึงประชาชนที่กำลังว่างงาน
          ได้เข้ามาศึกษาหาความรู้ และพัฒนาตนเอง
        </p>
      </div>
      <div class="info-card text-center text-md-left">
        <h2 class="mb-4">
          <span class="color-primary">F</span
          ><span class="color-secondary">A</span>
          <span class="color-text"> Station จุดมุ่งหมาย</span>
        </h2>
        <p
          class="color-text text-big"
          style="line-height: 40px;font-weight:300"
        >
          “ให้ประชาชนคนไทยทุกคนไม่จน ไม่ต้องหวังพึ่งโชคลาภ
          ไม่ต้องเสี่ยงโชคอย่างเช่นเคย โดยมีเป้าหมาย เพื่อสร้างความเข้าใจ
          ให้ประชาชนทุกคนสามารถบรรลุเป้าหมายทางการเงินที่วางไว้
          และมีคุณภาพชีวิตที่ดีขึ้น”
        </p>
      </div>
    </div>
    <!-- SECTION - Related courses -->
    <div class="container">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h4 class="color-primary font-weight-bold mr-3 mb-0">หลักสูตรแนะนำ</h4>
        <router-link
          class="more-course d-none d-sm-block"
          :to="{ name: 'Curriculum' }"
        >
          <p class="text-white text-big font-weight-bold m-0">
            ดูเพิ่มเติม
          </p>
        </router-link>
      </div>
      <div class="row" v-if="suggestList.length > 0">
        <div
          v-for="curriculum in suggestList"
          :key="curriculum.id"
          class="col-12 col-md-4 my-2"
        >
          <CourseCard :course="curriculum" type="curriculum" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีหลักสูตรแนะนำ</h3>
      </div>
      <router-link class="d-block d-sm-none" :to="{ name: 'Curriculum' }">
        <p
          class="color-secondary text-big text-center font-weight-bold m-0"
          style="text-decoration:underline; cursor:pointer;"
        >
          ดูเพิ่มเติม
        </p>
      </router-link>
    </div>
    <!-- SECTION - GET STARTED -->
    <div class="get-started mt-5">
      <!-- <div class="container">
        <div class="row justify-content-end">
          <div class="col-12 col-md-6 text-right">
            <h2>เข้าร่วมหลักสูตร</h2>
            <h2 class="color-secondary">
              ลดทันที 30% โปรโมชั่นนี้ ภายในวันนี้
            </h2>
            <button class="text-big enroll mb-2">Enroll Now</button>
            <p class="text-big color-secondary font-weight-bold">
              787 THB / Monthly
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ByteArkPlayerContainer from "@byteark/byteark-player-vue";
import FlipCountdown from "vue2-flip-countdown";
import CourseCard from "../components/CourseCard.vue";
import RegisterQuestion from "../components/RegisterQuestion.vue";
import baseUrl from "../util/backend";
import axios from "../api/faAPI";
import moment from "moment";
export default {
  components: {
    FlipCountdown,
    ByteArkPlayerContainer,
    CourseCard,
    RegisterQuestion,
  },
  data() {
    return {
      questionDisplay: false,
      isLive: false,
      countdown: null,
      displaySubscribe: false,
      subscribeType: "email",
      subscribeEmail: "",
      subscribeTel: "",
      bannerType: "image",
      bannerList: null,
      suggestList: [],
      playerOptions: {
        autoplay: true,
        fluid: true,
        aspectRatio: "16:9",
        // poster:
        //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        sources: {
          src: "",
          type: "application/x-mpegURL",
          // Optional
          title: "LIVE",
          videoId: "RI2PimuHxDXw",
          // poster:
          //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        },
      },
    };
  },
  watch: {
    subscribeType() {
      if (this.subscribeType === "email") {
        this.subscribeEmail = this.$store.getters.getUser?.email || "";
        this.subscribeTel = "";
      }
      if (this.subscribeType === "tel") {
        this.subscribeTel = this.$store.getters.getUser?.phone || "";
        this.subscribeEmail = "";
      }
    },
  },
  computed: {
    formatCountdownDate() {
      return moment(this.countdown.end_at).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    goToLesson() {
      this.$router.push({
        name: "Lesson",
        params: {
          videoUid: "64a6c17eae8e6b4fc7482642ce4f3ded", // or anything you want
        },
      });
    },
    goToBannerLink(link) {
      window.open(`${link}`, "_blank");
    },
    async submitSubscribe() {
      try {
        await axios.post(`${baseUrl}/createSubscribe`, {
          email: this.subscribeEmail,
          phone: this.subscribeTel,
          note: "",
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.subscribeEmail = "";
      this.subscribeTel = "";
      this.displaySubscribe = false;
    },
    async loadBanner() {
      try {
        const res = await axios.get(`${baseUrl}/getBanner`);
        this.bannerType = res.data.type;
        if (res.data.type === "image") {
          this.bannerList = res.data.data;
        }
        if (res.data.type === "live") {
          this.playerOptions.sources.src = res.data.data.url;
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async loadSuggest() {
      try {
        const res = await axios.get(`${baseUrl}/getSuggestCurriculum`);
        this.suggestList = res.data.data;
        console.log(this.suggestList);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async loadCountDown() {
      try {
        const res = await axios.get(`${baseUrl}/getCountdown`);
        console.log(res.data.countdown);
        this.countdown = res.data.countdown;
      } catch (err) {
        if (err.response) {
          console.log(err.response.data.error);
        } else {
          alert(err);
        }
      }
    },
    async checkSubmitSurvey() {
      if (this.$store.getters.isAuth) {
        try {
          const res = await axios.get(`${baseUrl}/checkUserResult`, {
            params: {
              user_id: this.$store.getters.getUser.user_id,
              survey_group_id: 1,
            },
          });
        } catch (err) {
          if (err.response) {
            console.log(err.response.data.error);
            this.questionDisplay = true;
          } else {
            alert(err);
          }
        }
      }
    },
  },
  created() {
    this.loadBanner();
    this.loadSuggest();
    this.loadCountDown();
    this.checkSubmitSurvey();
    if (this.$store.getters.isAuth) {
      this.subscribeEmail = this.$store.getters.getUser.email;
    }
  },
};
</script>

<style scoped>
@import "~@byteark/byteark-player-vue/dist/@byteark/byteark-player-vue.css";
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.video-container {
  position: relative;
  /* padding-top: 56.25%; */
  padding-top: 70%;
  width: 100%;
}

.video-player {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.banner >>> .VueCarousel-pagination {
  /* display: none; */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.banner >>> .VueCarousel-dot-container[style] {
  margin: 0 !important;
}
.banner >>> .VueCarousel-dot.VueCarousel-dot--active[style] {
  background-color: #07c5f8 !important;
  outline: none;
}
.banner >>> .VueCarousel-dot {
  background-color: #c4c4c4 !important;
  outline: none;
  margin: 0 !important;
}

.interest .switch {
  cursor: pointer;
  padding: 5px;
  color: #000;
  width: 100%;
  text-align: center;
  border: 2px solid #07c5f8;
}

.interest .switch.selected {
  color: #fff;
  background-color: #07c5f8;
  border: 2px solid #07c5f8;
  font-weight: 700;
}

.interest .switch:first-child {
  border-right: none;
}
.interest .switch:last-child {
  border-left: none;
}

.interest input {
  border: 1px solid #0430ad;
  margin: 20px 0;
  padding: 10px;
  font-size: 16px;
  outline: none;
  text-align: center;
}

.interest button {
  cursor: pointer;
  border: none;
  background-color: #07c5f8;
  padding: 5px 35px;
  color: #fff;
}

.promotion {
  position: absolute;
  width: 100%;
  top: 60%;
  transform: translateY(-50%);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 20px 0;
}

.promotion .logo {
  display: block;
  width: 100%;
  object-fit: contain;
}

.interest-btn {
  cursor: pointer;
  border: none;
  background-color: #0430ad;
  padding: 10px 25px;
}

.subscribe {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subscribe input[type="email"] {
  width: 100%;
  border: none;
  outline: none;
  text-align: center;
  padding: 10px;
}

.subscribe button {
  border: none;
  padding: 10px 15px;
  color: #fff;
  background-color: #07c5f8;
}

.info-card {
  padding: 10px 20px;
  margin-bottom: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.more-course {
  cursor: pointer;
  border: none;
  background-color: #07c5f8;
  padding: 10px 25px;
}

.get-started {
  position: relative;
  padding: 50px 0;
  background-size: cover;
  min-height: 300px;
  background-image: url("../assets/images/Rectangle.jpg");
  /* background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 1)
    ),
    url("../assets/images/Rectangle.jpg"); */
}

.enroll {
  color: #fff;
  border: none;
  padding: 10px 60px;
  background-color: #02c5f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.countdown {
  background: linear-gradient(
    90deg,
    rgba(4, 48, 173, 1) 0%,
    rgba(7, 197, 248, 1) 100%
  );
  width: 100%;
}

.clock {
  padding: 15px 0px;
}

.clock >>> .flip-clock__card {
  font-size: 30px !important;
}

.clock >>> .flip-clock__slot {
  font-size: 14px;
  color: #fff;
}

.clock >>> .flip-card__top,
.clock >>> .flip-card__back::before {
  color: #ddd !important;
  background: #025ca0;
}

.clock >>> .flip-card__bottom,
.clock >>> .flip-card__back-bottom {
  /* .clock >>> .flip-card__back::after { */
  color: #fff !important;
  background: #005bbe;
}

@media only screen and (min-width: 768px) {
  /* .slide-container {
    max-width: 1140px;
  }
  .banner >>> .VueCarousel-slide {
    padding: 30px 0;
  } */
  .thumbnail-container {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 33.33%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .info-card {
    padding: 20px 60px;
  }

  .banner >>> .VueCarousel-wrapper {
    background: linear-gradient(90deg, #bfeaf5 0%, #ecf1ff 75.52%);
  }

  .clock {
    padding: 0 15px;
  }

  .clock >>> .flip-clock__card {
    font-size: 28px !important;
  }

  .clock >>> .flip-clock__slot {
    font-size: 14px;
    color: #fff;
  }
}
</style>
