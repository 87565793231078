export default {
  namespaced: true,
  state() {
    return {
      visitedVideoList: [

      ],
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    },
  },
  actions: {
    getVisitedVideoList(context, payload) {
      context.commit("SET_VISITED_VIDEO_LIST", payload);
    },
  },
  mutations: {
    SET_VISITED_VIDEO_LIST(state, payload) {
      if (!state.visitedVideoList.includes(payload)) {
        state.visitedVideoList.push(payload);
      }
    },
  },
  getters: {
    getVisitedVideoList(state) {
      return state.visitedVideoList;
    },
  },
};
