import store from ".";

export default {
  getLoading(state) {
    return state.isLoading;
  },
  getCourseData(state) {
    const version = localStorage.getItem("version");
    return version === "1" ? state.courseData : state.courseDataV2;
  },
  getPermissionID(state) {
    return state.id;
  },
};
