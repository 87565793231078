<template>
  <div id="app">
    <!-- <the-header /> -->
    <!-- <router-view :key="$route.fullPath" /> -->
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        :z-index="10000000"
        background-color="#000"
        color="#fff"
      ></loading>
    </div>
    <router-view />
    <!-- <the-footer /> -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
};
// import TheHeader from "./components/TheHeader.vue";
// import TheFooter from "./components/TheFooter.vue";
// export default {
//   components: {
//     TheHeader,
//     TheFooter,
//   },
// };
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600&display=swap");
/* @media only screen and (min-width: 1500px) {
  .container {
    max-width: 1480px !important;
  }
} */

* {
  font-family: Kanit, Helvetica, Arial, sans-serif;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
}

button p {
  margin-bottom: 0;
}

button:disabled,
button[disabled] {
  background-color: #a1a1a1 !important;
}

/* #app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.color-primary {
  color: #8888ff !important;
}

.color-secondary {
  color: #07c5f8;
}

.color-text {
  color: #798da5;
}

h1 {
  font-size: 48px !important;
  /* font-size: 3rem !important; */
}

h2 {
  font-size: 32px !important;
  /* font-size: 2rem !important; */
}

h3 {
  font-size: 28px !important;
  /* font-size: 1.75rem !important; */
}

h4 {
  font-size: 24px !important;
  /* font-size: 1.5rem !important; */
}

p {
  font-size: 14px;
  word-wrap: break-word;
  /* font-size: 0.875rem !important; */
}

.text-big,
label {
  font-size: 18px;
  /* font-size: 1.125rem !important; */
}

.text-small {
  font-size: 12px;
}
</style>
