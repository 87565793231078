import CryptoJS from "crypto-js";
import state from "./state";
const key = state.key;
export default {
  setLoading(context, payload) {
    context.commit("SET_LOADING", payload);
  },

  // createMockToken(context, payload) {
  //   const data = JSON.stringify(payload.token);
  //   const encryptedToken = CryptoJS.AES.encrypt(data, key).toString();
  //   context.commit("MOCK_TOKEN", encryptedToken);
  //   console.log("encryptedToken", encryptedToken);
  // },

  decryptToken(context, payload) {
    // console.log(payload);
    const decrypted = CryptoJS.AES.decrypt(payload, key).toString(
      CryptoJS.enc.Utf8
    );
    context.commit("DECRYPTED", decrypted);
    // console.log("decrypted", decrypted);
    return decrypted;
  },
  getVideoByCourseId(context, payload) {
    const allVids = context.getters.getCourseData;
    return allVids.find((vid) => vid.course.course_id === payload);
  },
};
