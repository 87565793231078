import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("../layout/Main.vue"),
    children: [
      {
        path: "/",
        name: "CourseList",
        component: () => import("../views/TempCourse.vue"),
        meta: {
          title: "Sponga",
          description:
            "Welcome to 'Sponga' learning platform for VERSCAN users.",
          metaTags: [
            { property: "og:url", content: "https://dev.sponga.co" },
            { property: "og:type", content: "website" },
            { property: "og:title", content: "Sponga" },
            {
              property: "og:description",
              content:
                "Welcome to 'Sponga' learning platform for VERSCAN users.",
            },
            { property: "og:image", content: "/spongaBanner.png" },
          ],
        },
      },
      ...Array.from({ length: store.getters.getCourseData.length }, (_, i) => {
        const courseId = i + 1;
        const courseData = store.getters.getCourseData;
        return {
          path: `/lesson/${courseId}`,
          name: `Lesson-${courseId}`,
          component: () => import("../views/TempLesson.vue"),
          meta: {
            title: `${courseData[courseId - 1].course.title}`,
            permission: courseData[courseId - 1].course.permission,
            metaTags: [
              {
                property: "og:url",
                content: `https://dev.sponga.co/lesson/${courseId}`,
              },
              { property: "og:type", content: "website" },
              {
                property: "og:title",
                content: `${courseData[courseId - 1].course.title}`,
              },
              {
                property: "og:description",
                content: `${courseData[courseId - 1].course.sub_title}`,
              },
              {
                property: "og:image",
                content: `${courseData[courseId - 1].course.cover_img}`,
              },
            ],
          },
          props: { courseId },
        };
      }),

      // {
      //   path: "/",
      //   name: "Home",
      //   component: Home,
      // },
      // {
      //   path: "/lesson/:courseId",
      //   name: "Lesson",
      //   component: () => import("../views/Lesson.vue"),
      //   meta: { requiresAuth: true },
      // },
      {
        path: "/curriculum",
        name: "Curriculum",
        component: () => import("../views/Curriculum.vue"),
      },
      {
        path: "/curriculum/:curriculumId",
        name: "CurriculumDetail",
        component: () => import("../views/Courses.vue"),
      },
      {
        path: "/detail/:courseId",
        name: "Detail",
        component: () => import("../views/CourseDetail.vue"),
      },
      {
        path: "/payment/:courseId",
        name: "Payment",
        component: () => import("../views/Payment.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/terms",
        name: "Terms",
        component: () => import("../views/policy/Terms.vue"),
      },
      {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/policy/Privacy.vue"),
      },
      {
        path: "/deletion",
        name: "Deletion",
        component: () => import("../views/policy/Deletion.vue"),
      },
      {
        path: "/forget",
        name: "Forget",
        component: () => import("../views/ForgetPassword.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        component: () => import("../views/ResetPassword.vue"),
        meta: { requiresUnAuth: true },
      },
    ],
  },
  // {
  //   path: "",
  //   component: () => import("../layout/Profile.vue"),
  //   children: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       component: () => import("../views/profile/Profile.vue"),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/mycourse",
  //       name: "MyCourse",
  //       component: () => import("../views/profile/MyCourse.vue"),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/suggestion",
  //       name: "Suggestion",
  //       component: () => import("../views/profile/Suggestion.vue"),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/paymenthistory",
  //       name: "PayHistory",
  //       component: () => import("../views/profile/PayHistory.vue"),
  //       meta: { requiresAuth: true },
  //     },
  //   ],
  // },
  {
    path: "*",
    component: () => import("../views/Error404.vue"),
  },
  //loading
  {
    path: "/loading",
    name: "Loading",
    component: () => import("../views/Loading.vue"),
  },
  {
    path: "/landing",
    name: "Landing",
    component: () => import("../views/Landing.vue"),
    meta: {
      title: "Landing",
      description: "This is Landing Page of Sponga",
      metaTags: [
        { property: "og:url", content: "https://dev.sponga.co/landing" },
        { property: "og:type", content: "website" },
        { property: "og:title", content: "Sponga Landing Page" },
        { property: "og:description", content: "Sponga is coming soon" },
        { property: "og:image", content: "/landing_logo.png" },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
  routes,
});

const checkDomain = () => {
  if (
    window.location.hostname == "fa-station.web.app" ||
    window.location.hostname == "fa-station.firebaseapp.com"
  ) {
    window.location.replace("https://dev.fastation.co.th");
  }
  if (
    window.location.hostname == "staging-fa-station.web.app" ||
    window.location.hostname == "staging-fa-station.firebaseapp.com"
  ) {
    window.location.replace("https://staging.fastation.co.th");
  }
};

// router.beforeEach(async (to, from, next) => {
//   checkDomain();
//   if (localStorage.getItem("token") && !store.getters.isAuth) {
//     try {
//       await store.dispatch("autoLogin");
//       if (to.meta.requiresUnAuth && store.getters.isAuth) {
//         next({ name: "Home" });
//       } else {
//         next();
//       }
//     } catch (err) {
//       alert(err);
//       next({ name: "Home" });
//     }
//   } else {
//     // insert logic to lock path from meta here //
//     if (to.meta.requiresUnAuth && store.getters.isAuth) {
//       next(false);
//     } else if (to.meta.requiresAuth && !store.getters.isAuth) {
//       next({ name: "Login" });
//     } else {
//       next();
//     }
//   }
// });

router.afterEach((to, from) => {
  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }
});
// change meta data (digital ocean https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head) middle ware
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  permission(to, from, next);
});

function permission(to, from, next) {
  const right = to.query.right;
  const locale = to.query.locale;
  const version = to.query.version;

  if(version){
    localStorage.setItem("version", version);
  }
  if (right) {
    localStorage.setItem("right", right);
  }
  if (locale) {
    localStorage.setItem("locale", locale);
  }
  if (right || locale) {
    next({ name: to.name });
  }

  if (to.meta.permission?.length > 0) {
    // ถ้ามีการจำกัดสิทธิ์
    if (localStorage.getItem("right") != null) {
      store.dispatch("decryptToken", localStorage.getItem("right"));
      const decodedRight = store.getters.getPermissionID;
      if (
        to.meta.permission.includes(parseInt(decodedRight)) ||
        to.meta.permission.includes(0)
      ) {
        next();
      } else {
        // next(false);
        next({ name: "CourseList" });
      }
    } else {
      // ถ้าไม่มีการจำกัดสิทธิ์
      console.log("no right");
      if (to.meta.permission.includes(0)) {
        next();
      } else {
        next({ name: "CourseList" });
      }
    }
  } else {
    next();
  }
}

export default router;
