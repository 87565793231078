<template>
  <div>
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show" :class="{ small: small, xsmall: xsmall }">
        <img
          class="close-button d-none d-sm-block"
          v-if="!fixed"
          @click="tryClose"
          src="../assets/images/close.png"
          alt=""
        />
        <section>
          <div class="center">
            <slot></slot>
          </div>
        </section>
        <!-- <menu v-if="!fixed">
          <slot name="actions">
            <base-button mode="outline" @click="tryClose">ยกเลิก</base-button>
            <base-button @click="confirm">ยืนยัน</base-button>
          </slot>
        </menu> -->
      </dialog>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    xsmall: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close", "confirm"],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100000;
}

/* .center {
  text-align: center;
} */

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  z-index: 100001;
  /* border-radius: 7px; */
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem 1rem;
  margin: 0;
  overflow: hidden;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
}

section {
  padding-top: 0;
}

menu {
  /* padding: 1rem 0; */
  padding: 1rem 0 0 0;
  /* padding: 1rem; */
  display: flex;
  justify-content: center;
  margin: 0;
}

.dialog-enter,
.dialog-leave-to {
  opacity: 0;
  /* transform: scale(0.8); */
  /* transform: translate(0, -50%); */
}

.dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dialog-enter-to,
.dialog-leave {
  opacity: 1;
  /* transform: scale(1); */
  /* transform: translate(0, -50%); */
}

.small {
  /* left: calc(50% - 20rem); */
  /* left: 30%; */
  width: 80%;
  max-width: 500px;
}

.xsmall {
  /* left: calc(50% - 20rem); */
  /* left: 30%; */
  width: 80%;
  max-width: 300px;
}

.close-button {
  position: absolute;
  margin: 0;
  top: 15px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
  width: 20px;
}

@media only screen and (min-width: 992px) {
  dialog {
    width: 60%;
  }
}
</style>
